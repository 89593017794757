import React from 'react';
import ReactGA from 'react-ga4';

function App() {
    React.useEffect(() => {
        // ReactGA.pageview(window.location.pathname);
    }, []);

    const trackLinkClick = (link) => {
        // ReactGA.event({
        //     category: link,
        //     action: 'click',
        //     label: `${link} click`
        // })
    }

    return (
        <div className="container">

            <div id="main">

            Hi there! I'm Sonata Katt, a software engineer currently building online stores at Square.  I'm a big fan of&nbsp;
            <a onClick={() => trackLinkClick('pin')} href="https://maps.app.goo.gl/F3pahvVE973Gcgwi7?g_st=ic" target="_blank" rel="noopener noreferrer">s</a>
            <a onClick={() => trackLinkClick('gibran')} href="https://www.kahlilgibran.com/images/The%20Prophet%20Ebook%20by%20Kahlil%20Gibran.pdf" target="_blank" rel="noopener noreferrer">o</a>
            <a href="" target="_blank" rel="noopener noreferrer">m</a>
            <a onClick={() => trackLinkClick('mac')} href="https://youtu.be/1waiIvkcL_s" target="_blank" rel="noopener noreferrer">e</a>&nbsp;
            <a onClick={() => trackLinkClick('magic')} href="https://youtu.be/wO61D9x6lNY " target="_blank" rel="noopener noreferrer">t</a>
            <a onClick={() => trackLinkClick('ldr')} href="https://open.spotify.com/album/5HOHne1wzItQlIYmLXLYfZ" target="_blank" rel="noopener noreferrer">h</a>
            <a onClick={() => trackLinkClick('syria')} href="https://www.flickr.com/photos/taucci/5090257504/" target="_blank" rel="noopener noreferrer">i</a>
            <a onClick={() => trackLinkClick('bagel')} href="https://www.google.com/search?q=a+bagel" target="_blank" rel="noopener noreferrer">n</a>
            <a onClick={() => trackLinkClick('god')} href=" https://youtu.be/egUZkYORrhI" target="_blank" rel="noopener noreferrer">g</a>
            <a onClick={() => trackLinkClick('complex')} href="https://internetprincess.substack.com/p/standing-on-the-shoulders-of-complex" target="_blank" rel="noopener noreferrer">s</a> and most people.

            So let's get in touch!

            </div>
            <div className="links">
                <a onClick={() => trackLinkClick('email')} href="mailto:sonatakattbfmv@gmail.com">&nbsp;email </a>|
                <a onClick={() => trackLinkClick('resume')} href="https://www.youtube.com/watch?v=JFhqORL1cJs&ab_channel=Geistakranka" target="_blank" rel="noopener noreferrer"> resume </a>|

                <a onClick={() => trackLinkClick('github')} href="https://github.com/sonatata" target="_blank" rel="noopener noreferrer"> gh </a>|

                <a onClick={() => trackLinkClick('linkedin')} href="https://www.linkedin.com/in/sonata-katt-335598124/" target="_blank" rel="noopener noreferrer"> linkedin </a>

                {/* <a onClick={() => trackLinkClick('blog')} href="https://www.tumblr.com/blog_auth/irish-coldbrew-consumer" target="_blank" rel="noopener noreferrer"> tumblr&nbsp;</a> */}
            </div>
        </div>
    );
}
export default App;
